import "./index.scss";
import React, { useState, useEffect, useContext } from "react";
import { Button, Table, Pagination } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import ReportModal from "../../ui/CreateReportModal";
import _ from "lodash";
import moment from "moment";
import axios from "../../services/axios";
import RoleContext from "../../contexts/RoleContext";
import DatePicker from "react-datepicker";
import ReportTableRow from "./ReportTableRow";

function ReportsComponent() {
  const [addressFilter, setAddressFilter] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [analystFilter, setAnalystFilter] = useState(0);
  const [currentSort, setCurrentSort] = useState("domoreport_log_id");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [domoreports, setDomoreports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [direction, setDirection] = useState("desc");
  const [mdPeople, setMdPeople] = useState([]);
  const [limit, setLimit] = useState(10);
  const [publishedFilter, setPublishedFilter] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [idFilter, setIdFilter] = useState("");
  const [queuedReportKeys, setQueuedReportKeys] = useState([]);
  const { role } = useContext(RoleContext);

  useEffect(() => {
    hydrate();
  }, []);

  useEffect(() => {
    localStorage.removeItem("project-list-sort");
    let filteredDomoreports = [];
    if (
      publishedFilter ||
      analystFilter ||
      startDate ||
      endDate ||
      addressFilter ||
      idFilter
    ) {
      filteredDomoreports = domoreports.filter((report) => {
        const reportDate = moment(report.domoreport_log_added).format(
          "YYYY-MM-DD"
        );
        const start = moment(startDate).format("YYYY-MM-DD");
        const end = moment(endDate).format("YYYY-MM-DD");

        let fullAddress = "";
        if (report.address) {
          const {
            PropertyAddressCity,
            PropertyAddressFull,
            PropertyAddressState,
            PropertyAddressZIP,
          } = report.address;
          fullAddress = `${PropertyAddressFull}, ${PropertyAddressCity}, ${PropertyAddressState} ${PropertyAddressZIP}`;
        }

        return (
          (!publishedFilter || publishedFilter === report.status) &&
          (!analystFilter ||
            analystFilter === report.majordomo_people_ref_id) &&
          (!startDate || new Date(start) <= new Date(reportDate)) &&
          (!endDate || new Date(end) >= new Date(reportDate)) &&
          (addressFilter === "" ||
            fullAddress.toUpperCase().search(addressFilter.toUpperCase()) !==
              -1) &&
          (!idFilter ||
            report.domoreport_log_id === parseInt(idFilter) ||
            report.unique_id.includes(idFilter))
        );
      });
    } else {
      filteredDomoreports = domoreports;
    }
    setFilteredReports(filteredDomoreports);
  }, [
    publishedFilter,
    analystFilter,
    startDate,
    endDate,
    addressFilter,
    idFilter,
  ]);

  async function hydrate() {
    const queued = JSON.parse(localStorage.getItem("queued-projects")) || {};
    const keys = Object.keys(queued);
    if (keys.length && queued[keys[0]].length) {
      setQueuedReportKeys(keys);
    }
    await getMDPeople();
    await getDomoreports();
  }

  async function getMDPeople() {
    try {
      const mdPeopleResponse = await axios.get(
        "/domoreport/uploader/allMDPersons"
      );
      if (mdPeopleResponse.data && mdPeopleResponse.data.people) {
        setMdPeople(mdPeopleResponse.data.people);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getDomoreports() {
    try {
      const { data } = await axios.get(
        "/domoreport/uploader/unpublishedDomoreports"
      );
      if (data) {
        setDomoreports(data.reports);
        if (publishedFilter) {
          setFilteredReports(
            data.reports.filter((report) => report.status === publishedFilter)
          );
        } else {
          setFilteredReports(data.reports);
        }
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  // async function publishExplore(reportID) {
  //   setLoading(true);
  //   try {
  //     const publishExploreContent = await axios.put(
  //       `/domoreport/uploader/emailExploreContentFor/${reportID}`
  //     );
  //     if (publishExploreContent.data && publishExploreContent.data.success) {
  //       getDomoreports();
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     setLoading(false);
  //   }
  // }

  function _returnCaret(direction) {
    return direction === "asc" ? <span>&#9660;</span> : <span>&#9650;</span>;
  }

  function _sortBy(column) {
    if (column === currentSort) {
      let newDirection = direction === "asc" ? "desc" : "asc";
      setDirection(newDirection);
    } else {
      setCurrentSort(column);
      setDirection("asc");
    }
  }

  function _renderPagination(length) {
    let items = [];
    for (let number = 1; number <= length; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === activePage}
          onClick={() => setActivePage(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    return <Pagination style={{ flexWrap: "wrap" }}>{items}</Pagination>;
  }

  function clearFilters(e) {
    e.preventDefault();
    setIdFilter("");
    setAnalystFilter(0);
    setPublishedFilter("");
    setStartDate(null);
    setEndDate(null);
    setAddressFilter("");
  }

  function _renderReportRows(reports, mdPeople) {
    let orderedFilteredData = _.orderBy(
      reports,
      [currentSort],
      [`${direction}`]
    ).map((report) => (
      <ReportTableRow
        key={report.unique_id}
        getDomoreports={getDomoreports}
        report={report}
        loading={loading}
        setLoading={setLoading}
        setDownloading={setDownloading}
        queuedReportKeys={queuedReportKeys}
        mdPeople={mdPeople}
      />
    ));

    let skip = activePage === 1 ? 0 : parseInt(activePage - 1) * limit;
    return orderedFilteredData.slice(skip, skip + limit);
  }

  if (!domoreports) {
    return null;
  }

  return (
    <div className="md-scroll-wrapper">
      <div className="reports-container container my-5">
        {showCreateModal && (
          <ReportModal
            getDomoreports={getDomoreports}
            hideModal={() => setShowCreateModal(!showCreateModal)}
          />
        )}

        <div className="reports-header">
          <h2>Domoreports</h2>
          {role === "PUBLISHER" && (
            <Button
              href="#"
              className="col-2"
              onClick={(e) => {
                e.preventDefault();
                setShowCreateModal(!showCreateModal);
              }}
            >
              Create Report
            </Button>
          )}
        </div>
        <div className="pb-3">
          <label htmlFor="publishedFilter">
            Filters: Found {filteredReports.length} results{" "}
            {(publishedFilter ||
              analystFilter ||
              startDate ||
              endDate ||
              addressFilter) && (
              <a href="#" onClick={clearFilters}>
                Clear Filters
              </a>
            )}
          </label>
          <div className="reports-filters">
            <div>
              <input
                type="text"
                className="form-control"
                value={idFilter}
                onChange={(e) => setIdFilter(e.target.value)}
                placeholder="Report ID Search (case sensitive)"
              />
            </div>

            <div>
              <select
                className="form-control"
                value={publishedFilter}
                onChange={(e) => setPublishedFilter(e.target.value)}
                id="publishedFilter"
              >
                <option value="">No Status Filter</option>
                <option value="CREATED">Created</option>
                <option value="READY">Ready</option>
                <option value="IDENTIFYING">Identifying</option>
                {/* <option value="CLASSIFYING">Classifying</option> */}
                <option value="ON_HOLD">On Hold</option>
                <option value="CANCELLED">Cancelled</option>
                {role === "PUBLISHER" && (
                  <option value="IN_REVIEW">In Review</option>
                )}
                {role === "PUBLISHER" && (
                  <option value="PUBLISHED">Published</option>
                )}
              </select>
            </div>

            {role === "PUBLISHER" && (
              <div>
                <select
                  className="form-control md-people-select"
                  value={analystFilter}
                  onChange={(e) => setAnalystFilter(parseInt(e.target.value))}
                >
                  <option key={0} value={0}>
                    No Analyst Filter
                  </option>
                  {mdPeople &&
                    mdPeople.map((mdPerson) => {
                      return (
                        <option
                          key={mdPerson.majordomo_people_ref_id}
                          value={mdPerson.majordomo_people_ref_id}
                        >
                          {mdPerson.user_first_name} {mdPerson.user_last_name}
                        </option>
                      );
                    })}
                </select>
              </div>
            )}

            <div>
              <DatePicker
                className="form-control"
                selected={startDate}
                onSelect={(value) => setStartDate(value)}
                placeholderText="Start Date"
                popperPlacement="bottom"
                popperModifiers={{
                  flip: {
                    enabled: false,
                  },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                  },
                }}
              />
            </div>

            <div>
              <DatePicker
                className="form-control"
                selected={endDate}
                onSelect={(value) => setEndDate(value)}
                placeholderText="End Date"
                popperPlacement="bottom"
                popperModifiers={{
                  flip: {
                    enabled: false,
                  },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                  },
                }}
              />
            </div>

            <div>
              <input
                type="text"
                className="form-control"
                value={addressFilter}
                onChange={(e) => setAddressFilter(e.target.value)}
                placeholder="Address Search"
              />
            </div>

            <div className="results-limit">
              <Button
                className={`btn ${limit === 10 ? "active" : ""}`}
                onClick={() => setLimit(10)}
              >
                10
              </Button>
              <Button
                className={`btn ${limit === 50 ? "active" : ""}`}
                onClick={() => setLimit(50)}
              >
                50
              </Button>
              <Button
                className={`btn ${limit === 100 ? "active" : ""}`}
                onClick={() => setLimit(100)}
              >
                100
              </Button>
            </div>
          </div>
        </div>

        <div className="table-wrapper">
          <div
            className={`table-overlay ${
              loading || downloading ? "loading" : ""
            }`}
          >
            {downloading ? "Downloading Inspection..." : "Updating Table..."}
          </div>
          <Table
            striped
            bordered
            hover
            className={`${role === "PUBLISHER" ? "md-admin-table" : ""} ${
              loading ? "table-loading" : ""
            }`}
          >
            <thead>
              <tr>
                <th>ID</th>
                {role !== "ANALYST" && (
                  <th onClick={() => _sortBy("majordomo_people_ref_id")}>
                    Name{" "}
                    {currentSort === "majordomo_people_ref_id"
                      ? _returnCaret(direction)
                      : null}
                  </th>
                )}
                <th onClick={() => _sortBy("status")}>
                  Status{" "}
                  {currentSort === "status" ? _returnCaret(direction) : null}
                </th>
                {/* <th onClick={() => _sortBy("explore_content_published")}>
                  Explore
                </th> */}
                <th>Address</th>
                <th onClick={() => _sortBy("createdBy.user_first_name")}>
                  Created By{" "}
                  {currentSort === "createdBy.user_first_name"
                    ? _returnCaret(direction)
                    : null}
                </th>
                <th onClick={() => _sortBy("domoreport_log_added")}>
                  Date Added{" "}
                  {currentSort === "domoreport_log_added"
                    ? _returnCaret(direction)
                    : null}
                </th>
                {/* <th>Voucher</th> */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredReports.length > 0 &&
                _renderReportRows(filteredReports, mdPeople)}
            </tbody>
          </Table>
        </div>
        {_renderPagination(Math.ceil(filteredReports.length / limit))}
      </div>
    </div>
  );
}
export default withRouter(ReportsComponent);
