import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../../services/axios";

export default function QueuedProjectsModal({ show, close, handleOverwrite }) {
  const [queuedReports, setQueuedReports] = useState([]);

  useEffect(() => {
    getDomoreport();
  }, []);

  async function getDomoreport() {
    try {
      const { data } = await axios.get(
        "/domoreport/uploader/unpublishedDomoreports"
      );
      if (data) {
        const queued =
          JSON.parse(localStorage.getItem("queued-projects")) || {};
        const keys = Object.keys(queued);
        const reports = data.reports.filter((report) =>
          keys.includes(report.unique_id)
        );
        setQueuedReports(reports);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={close}
    >
      <Modal.Header closeButton>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center">
          You have projects queued for {queuedReports.length} report(s). To
          queue a project for this report, you must overwrite one of your
          previously queued reports.{" "}
        </p>
        <table className="p-4 w-100">
          <thead>
            <tr>
              <th colSpan="3">Report #</th>
              <th colSpan="3">Status</th>
              <th colSpan="3">Actions</th>
            </tr>
          </thead>
          <tbody>
            {queuedReports.map((r) => {
              return (
                <tr key={r.domoreport_log_id}>
                  <td colSpan="3">
                    <a
                      href={`/diy/report/${r.domoreport_log_id}/property/${r.property_log_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {r.domoreport_log_id}
                    </a>
                  </td>
                  <td colSpan="3">{r.status}</td>
                  <td colSpan="3">
                    <Button
                      variant="danger"
                      onClick={() => handleOverwrite(r.unique_id)}
                    >
                      Overwrite
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
}
