import "./index.scss";
import React, { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import InspectionDetails from "./inspectionDetails";
import ProjectsList from "./Projects/projectsList";
import EditProject from "./Projects/edit";
import CreateProject from "./Projects/createProject";
import Domoreport from "../Domoreport";
import HomeInspection from "../Home-Inspection";
import PhotosComponent from "../Photos";
import ExploreList from "./Explore/exploreList";
import CreateExplore from "./Explore/createExplore";
import EditExplore from "./Explore/editExplore";
import EditImageModal from "../../ui/EditImageModal";
import axios from "../../services/axios";
import RoleContext from "../../contexts/RoleContext";
import Geocode from "react-geocode";
import { getAllMDPeople, forceGetProperty } from "../../actions";
import EditQueuedProject from "./Projects/editQueued";
import DefectReview from "../DefectReview";

const mapStateToProps = (state) => ({
  mdPeople: state.mdpeople.people,
  property: state.properties.property,
});

function DiyComponent({ match, history }) {
  const [alerts, setAlerts] = useState([]);
  const [bookmark, setBookmark] = useState(null);
  const [queuedProject, setQueuedProject] = useState({});
  const [contextComment, setContextComment] = useState(null);
  const [contextPage, setContextPage] = useState(null);
  const [current_view, setCurrentView] = useState("inspection");
  const [date, setDate] = useState(null);
  const [domoreport, setDomoreport] = useState({});
  const [exploreData, setExploreData] = useState({});
  const [explore_resource, setExploreResource] = useState(null);
  const [showListView, setShowListView] = useState(true);
  const [location, setLocation] = useState({});
  const [message, setMessage] = useState("");
  const [page_number, setPageNumber] = useState(null);
  const [pdf_image, setPdfImage] = useState(null);
  const [project_id, setProjectId] = useState(null);
  const [projectsFetched, setProjectsFetched] = useState(false);
  const [queuedProjects, setQueuedProjects] = useState({});
  const [reportData, setReportData] = useState({});
  const [resource_title, setResourceTitle] = useState(null);
  const [showTools, setShowTools] = useState(false);
  const [step, setStep] = useState(0);
  const [updatingReport, setUpdatingReport] = useState(false);
  const [userCanEdit, setUserCanEdit] = useState(false);
  const [segment, setSegment] = useState(4);
  const sidebarRef = useRef(null);

  const { role } = useContext(RoleContext);
  const { params } = match;
  const { mdPeople, property } = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  const address = property
    ? property.address +
      ", " +
      property.city +
      ", " +
      property.state +
      " " +
      property.zip
    : "";

  useEffect(() => {
    if (role) {
      hydrate();
    }
  }, [role]);

  async function hydrate() {
    let newDate = new Date();
    let yesterday = newDate.getDate() - 1;
    newDate.setDate(yesterday);

    setDate(newDate);
    dispatch(getAllMDPeople());
    dispatch(forceGetProperty(match.params.property_id));
    // getProjects("priceHigh");
    getOneReport();
    getDomoreport();
    getExplore();

    localStorage.removeItem("checkedProjects");
  }

  async function getOneReport() {
    const { data } = await axios.get(
      `/domoreport/uploader/domoreport/${match.params.report_id}`
    );
    setUserCanEdit(
      role !== "ANALYST" || (role === "ANALYST" && data.majordomo_people_ref_id)
    );
    setDomoreport(data);
  }

  async function updateReport(data) {
    setUpdatingReport(true);
    try {
      const updatedReportResponse = await axios.put(
        `/domoreport/uploader/domoreport/${domoreport.domoreport_log_id}`,
        data
      );
      if (updatedReportResponse.data) {
        getOneReport();
        setUpdatingReport(false);
        setMessage("Report Updated Successfully");
      }
    } catch (error) {
      setMessage("There was an error saving.");
      setUpdatingReport(false);
    }
  }

  async function getDomoreport() {
    try {
      const reportResponse = await axios.get(
        `/domoreport/${match.params.report_id}`
      );
      if (reportResponse.data) {
        const { address, city, state, property_log_id } = reportResponse.data;
        localStorage.setItem("address", address + ", " + city + ", " + state);
        localStorage.setItem("current-property", property_log_id);

        // Geocode.setApiKey("AIzaSyDq3s89D1S_E4d28YjgmeiXwlLz1LlFAaU");
        // Geocode.fromAddress(address + ", " + city + ", " + state).then(
        //   (response) => {
        //     const { lat, lng } = response.results[0].geometry.location;
        //     setLocation({ lat, lng });
        //   }
        // );

        setReportData(reportResponse.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getExplore() {
    try {
      const exploreResponse = await axios.get(
        `/property/explore/${match.params.property_id}`
      );
      if (exploreResponse.data) {
        setExploreData(exploreResponse.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function uploadPhoto(data) {
    try {
      const uploadedPhotoResponse = await axios.post(
        `/domoreport/documentForDomoreport/${domoreport.domoreport_log_id}`,
        data,
        { headers: { "Content-Type": null } }
      );
      if (uploadedPhotoResponse.data) {
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function updateProperty(data) {
    const propertyID = property.property_log_id || domoreport.property_log_id;
    try {
      const updatedPropertyResponse = await axios.put(
        `/property/${propertyID}`,
        data
      );
      if (updatedPropertyResponse.data) {
        dispatch(forceGetProperty(propertyID));
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function assignAnalyst(e) {
    let data = {};

    if (e && e.target && e.target.value) {
      const person = mdPeople.filter(
        (mdPerson) =>
          mdPerson.majordomo_people_ref_id === parseInt(e.target.value)
      );
      data = { analystId: person[0].user_log_id };
    }

    try {
      const { status } = await axios.put(
        `/domoreport/${domoreport.domoreport_log_id}/assign-analyst`,
        data
      );
      if (status === 204) {
        getOneReport();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function submitForReview() {
    try {
      const submitResponse = await axios.put(
        `/domoreport/${domoreport.domoreport_log_id}/submit-for-review`
      );
      if (submitResponse.data != null) {
        history.push("/reports");
      }
    } catch (error) {
      console.error(error);
    }
  }

  function updatePageNumber(page) {
    const { pdfViewer } = window.PDFViewerApplication;
    if (page && page > 0 && page <= pdfViewer._pages.length) {
      if (
        window.PDFViewerApplication.findController &&
        window.PDFViewerApplication.findController._state
      ) {
        if (window.PDFViewerApplication.findController._state.query) {
          window.PDFViewerApplication.findController._state.query = "";
        }
        if (!window.PDFViewerApplication.findController._state.scrollMatches) {
          window.PDFViewerApplication.findController._state.scrollMatches = true;
        }
      }

      pdfViewer.currentPageNumber = page;
    }

    setPageNumber(page);
  }

  function handleRefresh() {
    if (current_view === "domoreport") {
      getDomoreport();
    } else if (current_view === "inspection") {
      // this.forceUpdate();
    } else if (current_view === "explore") {
      getExplore();
    }
  }

  function updateStep(step) {
    if (step === 6) {
      setResourceTitle(null);
    }

    setStep(step);
  }

  function scrollToAlert(e) {
    let container = document.getElementById("viewerContainer");
    let pageIndex = e.target[e.target.selectedIndex].getAttribute("data-value");
    let alertIndex = e.target.value;

    window.PDFViewerApplication.page = parseInt(pageIndex);
    alerts[pageIndex][alertIndex].alert.scrollIntoView();
    container.scrollTop -= 150;
  }

  const handleMouseDown = (e) => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.body.style.userSelect = "none";
  };

  const handleMouseMove = (e) => {
    if (sidebarRef.current) {
      const segment = getSegment(e.clientX);
      setSegment(segment);
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    document.body.style.userSelect = "auto";
  };

  const getSegment = (clientX) => {
    const segmentWidth = window.innerWidth / 12;
    return Math.min(8, Math.max(2, Math.ceil(clientX / segmentWidth)));
  };

  if (!domoreport && !property) {
    return null;
  }

  return (
    <div className="md-diy-page row">
      {pdf_image && (
        <EditImageModal
          src={pdf_image}
          title="Edit Image"
          updateStep={updateStep}
          setPdfImage={setPdfImage}
          uploadPhoto={uploadPhoto}
          reportID={domoreport.domoreport_log_id}
          propertyID={params.property_id}
        />
      )}

      <div
        className={`md-diy-sidebar`}
        style={{
          flex: `0 0 calc(${(segment / 12) * 100}% - 5px)`,
          maxWidth: `calc(${(segment / 12) * 100}% - 5px)`,
        }}
        ref={sidebarRef}
      >
        {message && (
          <div className="md-diy-message">
            <p>{message}</p>
            <p onClick={() => setMessage(null)}>x</p>
          </div>
        )}

        <div className="address-wrapper">
          <a
            href={`http://www.google.com/search?q=${address}`}
            className="md-diy-sidebar-subheader"
            target="_blank"
            rel="noopener noreferrer"
          >
            {address}
          </a>
        </div>

        {step === 0 &&
          Object.keys(domoreport).length > 0 &&
          domoreport.domoreport_log_id === parseInt(match.params.report_id) && (
            <InspectionDetails
              report={domoreport}
              role={role}
              property={property}
              mdPeople={mdPeople}
              reportID={domoreport.domoreport_log_id}
              updateReport={updateReport}
              updatingReport={updatingReport}
              getOneReport={() => getOneReport()}
              updateProperty={updateProperty}
              assignAnalyst={assignAnalyst}
              submitForReview={submitForReview}
              userCanEdit={userCanEdit}
            />
          )}

        {step === 1 && (
          <ProjectsList
            bookmark={bookmark}
            setQueuedProject={setQueuedProject}
            getOneReport={() => getOneReport()}
            report={domoreport}
            property={property}
            queuedProjects={queuedProjects}
            setQueuedProjects={setQueuedProjects}
            updateStep={updateStep}
            setProjectId={setProjectId}
            updateReport={updateReport}
            userCanEdit={userCanEdit}
            showListView={showListView}
            setBookmark={setBookmark}
            setShowListView={setShowListView}
            updatePageNumber={updatePageNumber}
            setMessage={setMessage}
            setProjectsFetched={setProjectsFetched}
          />
        )}

        {step === 2 && (
          <EditProject
            report={domoreport}
            property={property}
            projectId={project_id}
            uniqueId={domoreport.unique_id}
            userCanEdit={userCanEdit}
            getProperty={() =>
              dispatch(forceGetProperty(match.params.property_id))
            }
            updatePageNumber={updatePageNumber}
            updateReport={updateReport}
            updateStep={updateStep}
          />
        )}

        {step === 3 && (
          <CreateProject
            report={domoreport}
            property={property}
            updateStep={updateStep}
            reportID={params.report_id}
            propertyID={params.property_id}
            comment={contextComment}
            page={contextPage}
            setContextComment={setContextComment}
            setContextPage={setContextPage}
            setMessage={setMessage}
            getOneReport={getOneReport}
          />
        )}

        {step === 4 && (
          <DefectReview
            report={domoreport}
            reportId={params.report_id}
            uniqueId={domoreport.unique_id}
            queuedProjects={queuedProjects}
            setQueuedProjects={setQueuedProjects}
            setProjectsFetched={setProjectsFetched}
            userCanEdit={userCanEdit}
            setMessage={setMessage}
            getOneReport={getOneReport}
          />
        )}

        {step === 5 && (
          <PhotosComponent
            property={property}
            updateStep={updateStep}
            reportID={params.report_id}
            propertyID={params.property_id}
            userCanEdit={userCanEdit}
          />
        )}

        {step === 6 && (
          <ExploreList
            exploreData={exploreData}
            getExplore={getExplore}
            setResourceTitle={setResourceTitle}
            setExploreResource={setExploreResource}
            updateStep={updateStep}
            property={property}
            userCanEdit={userCanEdit}
          />
        )}

        {step === 7 && (
          <CreateExplore
            getExplore={getExplore}
            property={property}
            report={domoreport}
            title={resource_title}
            resource={explore_resource}
            setResourceTitle={setResourceTitle}
            updateStep={updateStep}
          />
        )}

        {step === 8 && (
          <EditExplore
            getExplore={getExplore}
            property={property}
            report={domoreport}
            title={resource_title}
            resource={explore_resource}
            setResourceTitle={setResourceTitle}
            updateStep={updateStep}
            userCanEdit={userCanEdit}
          />
        )}

        {step === 9 && (
          <EditQueuedProject
            report={domoreport}
            queuedProject={queuedProject}
            updateStep={updateStep}
            updatePageNumber={updatePageNumber}
          />
        )}

        <div
          className="md-diy-sidebar-footer"
          style={{
            flex: `0 0 calc(${(segment / 12) * 100}% - 5px)`,
            maxWidth: `calc(${(segment / 12) * 100}% - 5px)`,
          }}
        >
          <a
            href="/"
            className={step === 0 ? "nav-link active" : "nav-link"}
            onClick={(e) => {
              e.preventDefault();
              setStep(0);
            }}
          >
            <img
              src="/assets/icons/Inspection Company.svg"
              className="md-diy-input-icon"
              alt=""
            />{" "}
            Inspection
          </a>
          <a
            href="/"
            className={step === 4 ? "nav-link active" : "nav-link"}
            onClick={(e) => {
              e.preventDefault();
              setStep(4);
            }}
          >
            <i className="fa fa-search-plus mr-1" /> Defects
          </a>

          <a
            href="/"
            className={
              step === 1 || step === 2 || step === 3
                ? "nav-link active"
                : "nav-link"
            }
            onClick={(e) => {
              e.preventDefault();
              setStep(1);
            }}
          >
            <img
              src="/assets/icons/Project.svg"
              className="md-diy-input-icon"
              alt=""
            />{" "}
            Projects
          </a>

          <a
            href="/"
            className={step === 5 ? "nav-link active" : "nav-link"}
            onClick={(e) => {
              e.preventDefault();
              setStep(5);
            }}
          >
            <i className="fa fa-camera-retro mr-1" /> Photos
          </a>

          <a
            href="/"
            className={
              step === 6 || step === 7 ? "nav-link active" : "nav-link"
            }
            onClick={(e) => {
              e.preventDefault();
              setStep(6);
            }}
          >
            <i className="fa fa-globe mr-1" /> Explore
          </a>
        </div>
      </div>
      <div onMouseDown={handleMouseDown} className="resizer">
        <p>|</p>
      </div>
      <div className={`md-diy-inspection col-12 col-md-${12 - segment}`}>
        <div className="row md-actions-container">
          <div
            className="col md-actions"
            style={{
              flex: `0 0 ${((12 - segment) / 12) * 100}%`,
              maxWidth: `${((12 - segment) / 12) * 100}%`,
            }}
          >
            {window.PDFViewerApplication &&
            window.PDFViewerApplication.pdfViewer &&
            current_view === "inspection" ? (
              <div className="md-actions-page-number">
                <p>Page:</p>
                <input
                  type="number"
                  value={page_number || ""}
                  onChange={(e) => updatePageNumber(parseInt(e.target.value))}
                />{" "}
                / {window.PDFViewerApplication.pdfViewer._pages.length}
                {/* <select id="accessDropdown" onChange={(e) => scrollToAlert(e)}>
                  {alerts.map((pageAlerts, pageNum) => {
                    if (pageAlerts) {
                      return (
                        <optgroup label={`Page #${pageNum}`}>
                          {pageAlerts.map((alert, index) => {
                            return (
                              <option
                                key={alert.alert.getAttribute("id")}
                                data-value={alert.alert.getAttribute(
                                  "data-alert-page"
                                )}
                                value={index}
                              >
                                Alert #{index + 1}
                              </option>
                            );
                          })}
                        </optgroup>
                      );
                    }
                  })}
                </select> */}
              </div>
            ) : (
              <div className="md-actions-page-number">
                <p>Page:</p>
                <input type="text" value="--" disabled={true} /> / --
              </div>
            )}

            <div className="inspection-tools-wrapper">
              <Link
                to="#"
                className="btn btn-secondary d-none d-sm-flex"
                onClick={(e) => {
                  e.preventDefault();
                  setShowTools(!showTools);
                }}
              >
                Tools
              </Link>

              <div className={`inspection-tools ${showTools ? "active" : ""}`}>
                <a
                  href="https://www.calculator.net/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => setShowTools(false)}
                >
                  Standard calculator
                </a>

                <a
                  href="https://www.calculator.net/roofing-calculator.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => setShowTools(false)}
                >
                  Roofing calculator
                </a>

                <a
                  href={`https://www.google.com/maps/@?api=1&map_action=map&center=${location.lat},${location.lng}&zoom=20&basemap=satellite`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => setShowTools(false)}
                >
                  Google Earth
                </a>
              </div>
            </div>

            <Link
              to="#"
              className="btn btn-secondary d-none d-sm-flex"
              onClick={(e) => {
                e.preventDefault();
                setCurrentView("domoreport");
              }}
            >
              Domoreport
            </Link>

            <Link
              to="#"
              className="btn btn-secondary d-none d-sm-flex"
              onClick={(e) => {
                e.preventDefault();
                setCurrentView("inspection");
              }}
            >
              Home Inspection
            </Link>

            <Link
              to="#"
              className="btn btn-secondary d-none d-sm-flex"
              onClick={(e) => {
                e.preventDefault();
                handleRefresh();
              }}
            >
              Refresh
            </Link>
          </div>
        </div>

        {current_view === "domoreport" && reportData && (
          <div className="md-full-height-wrapper">
            <Domoreport
              reportData={reportData}
              reportID={params.report_id}
              propertyID={params.property_id}
              getDomoreport={getDomoreport}
            />
          </div>
        )}

        {current_view === "inspection" && (
          <HomeInspection
            alerts={alerts}
            report={domoreport}
            reportID={params.report_id}
            getOneReport={getOneReport}
            setPageNumber={setPageNumber}
            setContextComment={setContextComment}
            setContextPage={setContextPage}
            setAlerts={setAlerts}
            setPdfImage={setPdfImage}
            updateStep={updateStep}
            updateReport={updateReport}
            pageNumber={page_number}
            queuedProjects={queuedProjects}
            setMessage={setMessage}
            setQueuedProjects={setQueuedProjects}
            setProjectId={setProjectId}
            projectsFetched={projectsFetched}
          />
        )}
      </div>
    </div>
  );
}
export default withRouter(DiyComponent);
